import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import CREEDDialogTitle from "../../CREEDDialogTitle";
import LoadingButton from "../../LoadingButton";
import { MuiTelInput } from "mui-tel-input";
import { useCallback, useEffect, useState } from "react";
import { useCreateDiligenceContactMutation, useUpdateDiligenceContactMutation } from "../../../features/deals-api";
import * as Sentry from '@sentry/react';
import { useAppDispatch } from "../../../app/hooks";
import { snacked } from "../../../features/snackMessage-slice";
import { IDeal, IDealDiligenceContact } from "../../../schemas";


interface Props extends DialogProps {
  deal?: IDeal;
  type: 'lender' | 'borrower' | 'insurance' | 'legal' | 'title';
  contact?: IDealDiligenceContact;
  hasRole?: boolean;
}

export default function DiligenceContactDialog({deal, type, contact, hasRole, ...dialogProps}: Props): JSX.Element {
  
  const dispatch = useAppDispatch();

  const [role, setRole] = useState<string>('Lender Council');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');

  const [createContact, { isLoading: isCreateContactLoading }] = useCreateDiligenceContactMutation();
  const [updateContact, { isLoading: isUpdateContactLoading }] = useUpdateDiligenceContactMutation();

  const formSubmitted = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (deal?._id == null) {
      return;
    }

    try {
      if (contact == null) {
        await createContact({
          dealId: deal._id,
          type: type,
          role: hasRole ? role : undefined,
          name: name,
          email: email,
          phone: phone || undefined,
          address: address || undefined,
        }).unwrap();
  
        dispatch(snacked({
          message: 'Contact added',
          severity: 'success',
        }));
      } else {
        await updateContact({
          dealId: deal._id,
          type: type,
          contactId: contact._id,
          role: hasRole ? role : undefined,
          name: name,
          email: email,
          phone: phone || undefined,
          address: address || undefined,
        }).unwrap();
  
        dispatch(snacked({
          message: 'Contact saved',
          severity: 'success',
        }));
      }

      if (dialogProps.onClose != null) {
        dialogProps.onClose({}, 'escapeKeyDown');
      }

      if (contact == null) {
        setRole('Lender Council');
        setName('');
        setEmail('');
        setPhone('');
        setAddress('');
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      dispatch(snacked({
        message: 'Failed saving contact',
        severity: 'error',
      }));
    }
  }, [address, contact, createContact, deal?._id, dialogProps, dispatch, email, hasRole, name, phone, role, type, updateContact]);

  useEffect(() => {
    setRole(contact?.role ?? 'Lender Council');
    setName(contact?.name ?? '');
    setEmail(contact?.email ?? '');
    setPhone(contact?.phone ?? '');
    setAddress(contact?.address ?? '');
  }, [contact]);
  
  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title={contact != null ? 'Edit Contact' : 'Add Contact'}
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <form onSubmit={formSubmitted}>
        <DialogContent>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}>
            {hasRole &&
              <FormControl required>
                <InputLabel id='role'>{'Role'}</InputLabel>
                <Select
                  id='role'
                  label='Role'
                  value={role}
                  onChange={e => setRole(e.target.value)}>
                  <MenuItem value='Lender Council'>{'Lender Council'}</MenuItem>
                  <MenuItem value='Borrower Council'>{'Borrower Council'}</MenuItem>
                  <MenuItem value='Other'>{'Other'}</MenuItem>
                </Select>
              </FormControl>
            }
            <TextField
              label='Name'
              required
              value={name}
              onChange={e => setName(e.target.value)} />
            <TextField
              label='Email'
              required
              type='email'
              value={email}
              onChange={e => setEmail(e.target.value)} />
            <MuiTelInput
              defaultCountry={'US'}
              onlyCountries={['US']}
              disableDropdown
              InputProps={{
                sx: {
                  borderRadius: '8px',
                }
              }}
              label='Phone'
              value={phone}
              onChange={value => setPhone(value)} />
            <TextField
              label='Address'
              value={address}
              onChange={e => setAddress(e.target.value)} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              flex: 1,
            }}
            onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
            {'Cancel'}
          </Button>
          <LoadingButton
            sx={{
              flex: 1,
            }}
            type='submit'
            variant='contained'
            loading={isCreateContactLoading || isUpdateContactLoading}>
            {contact != null ? 'Save changes' : 'Add contact'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
